<template>
  <div class="navbar-container main-menu-content">
    <div class="patient-summary">
      <patient-summary></patient-summary>
    </div>
    <horizontal-nav-menu-items :items="navMenuItems" />
  </div>
</template>

<script>
import navMenuItems from '@/navigation/horizontal'
import HorizontalNavMenuItems from './components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue'
import PatientSummary from '@core/layouts/components/PatientSummary.vue'

export default {
  components: {
    HorizontalNavMenuItems, PatientSummary
  },
  setup() {
    return {
      navMenuItems,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/horizontal-menu.scss";
</style>
